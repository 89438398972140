.question-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.question-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.submit-button {
  background-color: #ff9823;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 4px;
  
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: orangered;
  }
}

.title {
  margin-top: 3rem;
  font-size: 26px;
  font-weight: bold;
}

.title3 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 2rem;
}
