.score-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.score-display {
  font-size: 24px;
  font-weight: bold;
  color: #3f51b5;
}