.question-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  width: 90%;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.8);
}

.question {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.option input[type="radio"] {
  margin-right: 8px;
}
