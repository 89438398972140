.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffce55;
  background-image: url("bg.png");
  background-size: cover;
  width: 100%;
  min-width: 300px;
}
.login-button {
  background-color: #00b900;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #008a00;
  }
}

.button-wrapper {
  margin: 2rem;
}

.title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 1px;
}

.title2 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 5rem;
}

.title4 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 3rem;
}
.sub-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 2px;
}

.content {
  font-size: 18px;
}

.content2 {
  font-size: 18px;
  margin-bottom: 2rem;
}
