.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.question-page {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  // gap: 0.8rem;
}

.submit-button {
  background-color: orange;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: orangered;
  }
}
