.answer-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
  margin-bottom: 10;
}

.answer-item__question {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.answer-item__details {
  display: flex;
  justify-content: space-between;
}

.answer-item__detail {
  display: flex;
  flex-direction: column;
}

.answer-item__label {
  font-size: 0.9rem;
  color: #777;
}

.answer-item__value {
  font-size: 1rem;
}

.answer-item__correct {
  color: #4caf50;
}

.answer-item__incorrect {
  color: #f44336;
}
