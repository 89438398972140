body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffce55;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #ffce55;
}

.App-header {
  background-color: #3f51b5;
  padding: 20px;
  color: FFCE55;
  width: 100%;
  text-align: center;
}

.App-content {
  width: 100%;
  max-width: 960px;
  padding: 20px;
  box-sizing: border-box;
}

.App-footer {
  background-color: #3f51b5;
  padding: 10px;
  color: white;
  width: 100%;
  text-align: center;
}
